<template>
  <button class="db-btn-outline sm danger modal-btn m-0.5">
    <i class="lab lab-delete"></i>
    <span>{{ $t('button.delete') }}</span>
  </button>
</template>

<script>
export default {
  name: 'SmDeleteComponent.vue'
}
</script>

<style scoped></style>
