import axiosInstance from '../../plugin/axios'
import appService from '../../services/appService'

export const frontendLanguage = {
  namespaced: true,
  state: {
    lists: [],
    show: {}
  },
  getters: {
    lists: function (state) {
      return state.lists
    },
    show: function (state) {
      return state.show
    }
  },
  actions: {
    lists: function (context, payload) {
      return new Promise((resolve, reject) => {
        let url = 'frontend/language'
        if (payload) {
          url = url + appService.requestHandler(payload)
        }
        axiosInstance
          .get(url)
          .then((res) => {
            if (typeof payload.vuex === 'undefined' || payload.vuex === true) {
              context.commit('lists', res.data.data)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    show: function (context, payload) {
      if (payload) {
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`frontend/language/show/${payload}`)
            .then((res) => {
              context.commit('show', res.data.data)
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    }
  },
  mutations: {
    lists: function (state, payload) {
      state.lists = payload
    },
    show: function (state, payload) {
      state.show = payload
    }
  }
}
