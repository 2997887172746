<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'RoleComponent.vue'
}
</script>

<style scoped></style>
