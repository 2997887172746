import axiosInstance from '../../plugin/axios'

export const permission = {
  namespaced: true,
  state: {
    lists: []
  },
  getters: {
    lists: function (state) {
      return state.lists
    }
  },
  actions: {
    lists: function (context, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`admin/setting/permission/${payload}`)
          .then((res) => {
            if (typeof payload.vuex === 'undefined' || payload.vuex === true) {
              context.commit('lists', res.data.data)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    save: function (context, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`admin/setting/permission/${payload.id}`, { permissions: payload.form })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },
  mutations: {
    lists: function (state, payload) {
      state.lists = payload
    }
  }
}
