<template>
  <button class="db-btn-outline sm success modal-btn m-0.5">
    <i class="lab lab-edit-line"></i>
    <span>{{ $t('button.edit') }}</span>
  </button>
</template>

<script>
export default {
  name: 'SmModalEditComponent.vue'
}
</script>

<style scoped></style>
