import appService from '../../services/appService'
import axiosInstance from '../../plugin/axios'

export const subscriber = {
  namespaced: true,
  state: {
    lists: [],
    page: {},
    pagination: [],
    show: {},
    temp: {
      temp_id: null,
      isEditing: false
    }
  },
  getters: {
    lists: function (state) {
      return state.lists
    },
    pagination: function (state) {
      return state.pagination
    },
    page: function (state) {
      return state.page
    },
    temp: function (state) {
      return state.temp
    }
  },
  actions: {
    lists: function (context, payload) {
      return new Promise((resolve, reject) => {
        let url = 'admin/subscriber'
        if (payload) {
          url = url + appService.requestHandler(payload)
        }
        axiosInstance
          .get(url)
          .then((res) => {
            if (typeof payload.vuex === 'undefined' || payload.vuex === true) {
              context.commit('lists', res.data.data)
              context.commit('page', res.data.meta)
              context.commit('pagination', res.data)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    destroy: function (context, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`admin/subscriber/${payload.id}`)
          .then((res) => {
            context.dispatch('lists', payload.search).then().catch()
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    export: function (context, payload) {
      return new Promise((resolve, reject) => {
        let url = 'admin/subscriber/export'
        if (payload) {
          url = url + appService.requestHandler(payload)
        }
        axiosInstance
          .get(url, { responseType: 'blob' })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    sendEmail: function (context, payload) {
      return new Promise((resolve, reject) => {
        let method = axiosInstance.post
        let url = '/admin/subscriber/send-email'
        method(url, payload.form)
          .then((res) => {
            context.dispatch('lists', payload.search).then().catch()
            context.commit('reset')
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    reset: function (context) {
      context.commit('reset')
    }
  },
  mutations: {
    lists: function (state, payload) {
      state.lists = payload
    },
    pagination: function (state, payload) {
      state.pagination = payload
    },
    page: function (state, payload) {
      if (typeof payload !== 'undefined' && payload !== null) {
        state.page = {
          from: payload.from,
          to: payload.to,
          total: payload.total
        }
      }
    },
    temp: function (state, payload) {
      state.temp.temp_id = payload
      state.temp.isEditing = true
    },
    reset: function (state) {
      state.temp.temp_id = null
      state.temp.isEditing = false
    }
  }
}
