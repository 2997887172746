<template>
  <button class="db-card-filter-btn dropdown-btn">
    <i class="lab lab-file-export lab-font-size-17"></i>
    <span>{{ $t('button.export') }}</span>
  </button>
</template>

<script>
export default {
  name: 'ExportComponent.vue'
}
</script>
