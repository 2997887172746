<template>
  <LoadingComponent :props="loading" />
  <SmModalCreateComponent :props="addButton" />

  <div id="modal" class="modal">
    <div class="modal-dialog">
      <div class="modal-header">
        <h3 class="modal-title">{{ $t('menu.variations') }}</h3>
        <button
          class="modal-close fa-solid fa-xmark text-xl text-slate-400 hover:text-red-500"
          @click="reset"
        ></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="save">
          <div class="form-row">
            <div class="form-col-12 sm:form-col-6">
              <label for="name" class="db-field-title required">{{ $t('label.name') }}</label>
              <input
                v-model="props.form.name"
                v-bind:class="errors.name ? 'invalid' : ''"
                type="text"
                id="name"
                class="db-field-control"
              />
              <small class="db-field-alert" v-if="errors.name">{{ errors.name[0] }}</small>
            </div>

            <div class="form-col-12 sm:form-col-6">
              <label for="price" class="db-field-title required">{{
                $t('label.additional_price')
              }}</label>
              <input
                v-on:keypress="numberOnly($event)"
                v-model="props.form.price"
                v-bind:class="errors.price ? 'invalid' : ''"
                type="text"
                id="price"
                class="db-field-control"
              />
              <small class="db-field-alert" v-if="errors.price">{{ errors.price[0] }}</small>
            </div>

            <div class="form-col-12 sm:form-col-6">
              <label for="item_attribute" class="db-field-title">{{ $t('label.attribute') }}</label>
              <vue-select
                class="db-field-control f-b-custom-select"
                id="item_attribute"
                v-bind:class="errors.item_attribute_id ? 'invalid' : ''"
                v-model="props.form.item_attribute_id"
                :options="itemAttributes"
                label-by="name"
                value-by="id"
                :closeOnSelect="true"
                :searchable="true"
                :clearOnClose="true"
                placeholder="--"
                search-placeholder="--"
              />
              <small class="db-field-alert" v-if="errors.item_attribute_id">{{
                errors.item_attribute_id[0]
              }}</small>
            </div>

            <div class="form-col-12 sm:form-col-6">
              <label class="db-field-title required" for="active">{{ $t('label.status') }}</label>
              <div class="db-field-radio-group">
                <div class="db-field-radio">
                  <div class="custom-radio">
                    <input
                      :value="enums.statusEnum.ACTIVE"
                      v-model="props.form.status"
                      id="active"
                      type="radio"
                      class="custom-radio-field"
                    />
                    <span class="custom-radio-span"></span>
                  </div>
                  <label for="active" class="db-field-label">{{ $t('label.active') }}</label>
                </div>
                <div class="db-field-radio">
                  <div class="custom-radio">
                    <input
                      :value="enums.statusEnum.INACTIVE"
                      v-model="props.form.status"
                      type="radio"
                      id="inactive"
                      class="custom-radio-field"
                    />
                    <span class="custom-radio-span"></span>
                  </div>
                  <label for="inactive" class="db-field-label">{{ $t('label.inactive') }}</label>
                </div>
              </div>
            </div>

            <div class="form-col-12">
              <label for="caution" class="db-field-title">{{ $t('label.caution') }}</label>
              <textarea
                v-model="props.form.caution"
                v-bind:class="errors.caution ? 'invalid' : ''"
                id="caution"
                class="db-field-control"
              ></textarea>
              <small class="db-field-alert" v-if="errors.caution">{{ errors.caution[0] }}</small>
            </div>

            <div class="form-col-12">
              <div class="modal-btns">
                <button type="button" class="modal-btn-outline modal-close" @click="reset">
                  <i class="lab lab-close"></i>
                  <span>{{ $t('button.close') }}</span>
                </button>

                <button type="submit" class="db-btn py-2 text-white bg-primary">
                  <i class="lab lab-save"></i>
                  <span>{{ $t('button.save') }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import SmModalCreateComponent from '../../components/buttons/SmModalCreateComponent.vue'
import LoadingComponent from '../../components/LoadingComponent.vue'
import alertService from '../../../../services/alertService'
import appService from '../../../../services/appService'
import statusEnum from '../../../../enums/modules/statusEnum'

export default {
  name: 'ItemVariationCreateComponent',
  components: { SmModalCreateComponent, LoadingComponent },
  props: ['props'],
  data() {
    return {
      loading: {
        isActive: false
      },
      enums: {
        statusEnum: statusEnum,
        statusEnumArray: {
          [statusEnum.ACTIVE]: this.$t('label.active'),
          [statusEnum.INACTIVE]: this.$t('label.inactive')
        }
      },
      errors: {}
    }
  },
  computed: {
    addButton: function () {
      return { title: this.$t('button.add_variation') }
    },
    itemAttributes: function () {
      return this.$store.getters['itemAttribute/lists']
    }
  },
  mounted() {
    this.$store.dispatch('itemAttribute/lists', {
      paginate: 0,
      order_column: 'id',
      order_type: 'desc',
      status: statusEnum.ACTIVE
    })
  },
  methods: {
    numberOnly: function (e) {
      return appService.floatNumber(e)
    },
    reset: function () {
      appService.modalHide()
      this.$store.dispatch('itemVariation/reset').then().catch()
      this.errors = {}
      this.$props.props.form = {
        name: '',
        price: null,
        item_attribute_id: null,
        caution: '',
        status: statusEnum.ACTIVE
      }
    },
    save: function () {
      try {
        const tempId = this.$store.getters['itemVariation/temp'].temp_id
        this.loading.isActive = true
        this.$store
          .dispatch('itemVariation/save', this.props)
          .then((res) => {
            appService.modalHide()
            this.loading.isActive = false
            alertService.successFlip(tempId === null ? 0 : 1, this.$t('label.variation'))
            this.props.form = {
              name: '',
              price: null,
              item_attribute_id: null,
              caution: '',
              status: statusEnum.ACTIVE
            }
            this.errors = {}
          })
          .catch((err) => {
            this.loading.isActive = false
            this.errors = err.response.data.errors
          })
      } catch (err) {
        this.loading.isActive = false
        alertService.error(err)
      }
    }
  }
}
</script>
