import router from '../router'
import ENV from '../config/env'
import axiosInstance from './axios'

const API_KEY = ENV.API_KEY

export default {
  setup: (store) => {
    axiosInstance.interceptors.request.use(
      (config) => {
        config.headers['x-api-key'] = API_KEY
        if (localStorage.getItem('vuex')) {
          const vuex = JSON.parse(localStorage.getItem('vuex'))
          const token = vuex.auth.authToken
          const language = vuex.globalState.lists.language_code
          config.headers['Authorization'] = token ? `Bearer ${token}` : ''
          config.headers['x-localization'] = language
        }
        return config
      },
      (error) => Promise.reject(error)
    )
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          store
            .dispatch('logout')
            .then((res) => {
              router.push({ name: 'auth.login' })
            })
            .catch()
        }
      }
    )
  }
}
