import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import { auth } from './modules/auth'
import { company } from './modules/company'
import { itemCategory } from './modules/itemCategory'
import { itemAttribute } from './modules/itemAttribute'
import { slider } from './modules/slider'
import { branch } from './modules/branch'
import { offer } from './modules/offer'
import { item } from './modules/item'
import { itemVariation } from './modules/itemVariation'
import { onlineOrder } from './modules/onlineOrder'
import { tax } from './modules/tax'
import { currency } from './modules/currency'
import { mail } from './modules/mail'
import { menuSection } from './modules/menuSection'
import { page } from './modules/page'
import { notification } from './modules/notification'
import { pushNotification } from './modules/pushNotification'
import { menuTemplate } from './modules/menuTemplate'
import { coupon } from './modules/coupon'
import { customer } from './modules/customer'
import { otp } from './modules/otp'
import { administrator } from './modules/administrator'
import { deliveryBoy } from './modules/deliveryBoy'
import { deliveryBoyAddress } from './modules/deliveryBoyAddress'
import { defaultAccess } from './modules/defaultAccess'
import { administratorAddress } from './modules/administratorAddress'
import { customerAddress } from './modules/customerAddress'
import { socialMedia } from './modules/socialMedia'
import { analytic } from './modules/analytic'
import { analyticSection } from './modules/analyticSection'
import { role } from './modules/role'
import { permission } from './modules/permission'
import { cookies } from './modules/cookies'
import { theme } from './modules/theme'
import { timeSlot } from './modules/timeSlot'
import { employee } from './modules/employee'
import { employeeAddress } from './modules/employeeAddress'
import { itemExtra } from './modules/itemExtra'
import { itemAddon } from './modules/itemAddon'
import { language } from './modules/language'
import { timezone } from './modules/timezone'
import { site } from './modules/site'
import { dashboard } from './modules/dashboard'
import { orderSetup } from './modules/orderSetup'
import { offerItem } from './modules/offerItem'
import { paymentGateway } from './modules/paymentGateway'
import { smsGateway } from './modules/smsGateway'
import { salesReport } from './modules/salesReport'
import { itemsReport } from './modules/itemsReport'
import { message } from './modules/message'
import { countryCode } from './modules/countryCode'
import { backendGlobalState } from './modules/backendGlobalState'
import { myOrderDetails } from './modules/myOrderDetails'
import { posCart } from './modules/posCart'
import { posOrder } from './modules/posOrder'
import { transaction } from './modules/transaction'
import { notificationAlert } from './modules/notificationAlert'
import { creditBalanceReport } from './modules/creditBalanceReport'
import { deliveryBoyOrder } from './modules/deliveryBoyOrder'
import { user } from './modules/user'
import { posCategory } from './modules/posCategory'
import { pwa } from './modules/pwa'
import { whatsapp } from './modules/whatsapp'
import { subscriber } from './modules/subscriber'
import { frontendLanguage } from './modules/frontendLanguage'
import { frontendSetting } from './modules/frontendSetting'
import { globalState } from './modules/globalState'

export default new createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    company,
    itemCategory,
    itemAttribute,
    slider,
    branch,
    offer,
    item,
    itemVariation,
    tax,
    currency,
    mail,
    pushNotification,
    notification,
    page,
    onlineOrder,
    menuSection,
    menuTemplate,
    coupon,
    customer,
    customerAddress,
    otp,
    administrator,
    deliveryBoy,
    deliveryBoyAddress,
    defaultAccess,
    administratorAddress,
    socialMedia,
    analytic,
    analyticSection,
    role,
    permission,
    cookies,
    theme,
    timeSlot,
    employee,
    employeeAddress,
    itemExtra,
    itemAddon,
    language,
    timezone,
    site,
    dashboard,
    orderSetup,
    offerItem,
    paymentGateway,
    smsGateway,
    salesReport,
    itemsReport,
    message,
    countryCode,
    backendGlobalState,
    myOrderDetails,
    posCart,
    posOrder,
    transaction,
    notificationAlert,
    creditBalanceReport,
    deliveryBoyOrder,
    user,
    posCategory,
    pwa,
    whatsapp,
    subscriber,
    frontendLanguage,
    frontendSetting,
    globalState
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'globalState', 'posCart']
    })
  ]
})
