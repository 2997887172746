<template>
  <aside class="db-sidebar">
    <div class="db-sidebar-header">
      <router-link class="w-24" :to="{ name: 'admin.dashboard' }">
        <img :src="setting.theme_logo" alt="logo" />
      </router-link>
      <button class="fa-solid fa-xmark xmark-btn close-db-menu"></button>
    </div>
    <!--{{ menus }}-->
    <nav class="db-sidebar-nav">
      <ul class="db-sidebar-nav-list" v-if="menus.length > 0" v-for="menu in menus" :key="menu">
        <li class="db-sidebar-nav-item" v-if="menu.url === '#'">
          <a href="javascript:void(0);" class="db-sidebar-nav-title">
            {{ $t('menu.' + menu.language) }}
          </a>
        </li>

        <li class="db-sidebar-nav-item" v-else>
          <router-link :to="'/admin/' + menu.url" class="db-sidebar-nav-menu">
            <i class="text-sm" :class="menu.icon"></i>
            <span class="text-base flex-auto">{{ $t('menu.' + menu.language) }}</span>
          </router-link>
        </li>

        <li class="db-sidebar-nav-item" v-if="menu.children" v-for="children in menu.children">
          <router-link :to="'/admin/' + children.url" class="db-sidebar-nav-menu">
            <i class="text-sm" :class="children.icon"></i>
            <span class="text-base flex-auto">{{ $t('menu.' + children.language) }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'BackendMenuComponent',
  data: function () {
    return {
      activeParentId: 1,
      activeChildId: 0
    }
  },
  computed: {
    setting: function () {
      return this.$store.getters['frontendSetting/lists']
    },
    menus: function () {
      return this.$store.getters.authMenu
    }
  }
}
</script>
