<template>
  <div :dir="direction">
    <main class="db-main" v-if="logged">
      <BackendNavbarComponent />
      <BackendMenuComponent />
      <router-view></router-view>
    </main>

    <div v-if="!logged">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import displayModeEnum from '../enums/modules/displayModeEnum'
import BackendMenuComponent from './layouts/backend/BackendMenuComponent.vue'
import BackendNavbarComponent from './layouts/backend/BackendNavbarComponent.vue'

export default {
  name: 'DefaultComponent',
  components: {
    BackendMenuComponent,
    BackendNavbarComponent
  },
  computed: {
    direction: function () {
      return this.$store.getters['frontendLanguage/show'].display_mode === displayModeEnum.RTL
        ? 'rtl'
        : 'ltr'
    },
    logged: function () {
      return this.$store.getters.authStatus
    }
  },
  beforeMount() {
    this.$store.dispatch('frontendSetting/lists').catch()
  }
}
</script>
