/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import { createApp, h } from 'vue'
import DefaultComponent from './components/DefaultComponent.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueSimpleAlert from 'vue3-simple-alert'
import VueNextSelect from 'vue-next-select'
import axiosInstance from './plugin/axios'
import 'vue-next-select/dist/index.css'
import './index.css'
import * as AxiosInterceptors from './plugin/axiosInterceptors'

/* Start tooltip alert code */
const options = {
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
}
/* End tooltip alert code */

const app = createApp({
  render: () => h(DefaultComponent)
})
app.component('default-component', DefaultComponent)
app.component('vue-select', VueNextSelect)
app.use(router)
app.use(store)
app.use(VueSimpleAlert)
app.use(Toast, options)
app.use(i18n)

// Configure global properties
app.config.globalProperties.$axios = axiosInstance
app.provide('axios', axiosInstance)
AxiosInterceptors.default.setup(store)

app.mount('#app')
