<template>
  <LoadingComponent :props="loading" />
  <SmModalCreateComponent :props="addButton" />

  <div id="modal" class="modal">
    <div class="modal-dialog">
      <div class="modal-header">
        <h3 class="modal-title">{{ $t('menu.languages') }}</h3>
        <button
          class="modal-close fa-solid fa-xmark text-xl text-slate-400 hover:text-red-500"
          @click="reset"
        ></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="save">
          <div class="form-row">
            <div class="form-col-12 sm:form-col-6">
              <label for="name" class="db-field-title required">{{ $t('label.name') }}</label>
              <input
                v-model="props.form.name"
                v-bind:class="errors.name ? 'invalid' : ''"
                type="text"
                id="name"
                class="db-field-control"
              />
              <small class="db-field-alert" v-if="errors.name">{{ errors.name[0] }}</small>
            </div>

            <div class="form-col-12 sm:form-col-6">
              <label for="code" class="db-field-title required">{{ $t('label.code') }}</label>
              <input
                v-model="props.form.code"
                v-bind:class="errors.code ? 'invalid' : ''"
                type="text"
                id="code"
                class="db-field-control"
              />
              <small class="db-field-alert" v-if="errors.code">{{ errors.code[0] }}</small>
            </div>

            <div class="form-col-12 sm:form-col-6">
              <label for="image" class="db-field-title">{{ $t('label.image') }}</label>
              <input
                @change="changeImage"
                v-bind:class="errors.image ? 'invalid' : ''"
                id="image"
                type="file"
                class="db-field-control"
                ref="imageProperty"
                accept="image/png, image/jpeg, image/jpg"
              />
              <small class="db-field-alert" v-if="errors.image">{{ errors.image[0] }}</small>
            </div>

            <div class="form-col-12 sm:form-col-6">
              <label class="db-field-title required" for="ltr">{{
                $t('label.display_mode')
              }}</label>
              <div class="db-field-radio-group">
                <div class="db-field-radio">
                  <div class="custom-radio">
                    <input
                      :value="enums.displayModeEnum.LTR"
                      v-model="props.form.display_mode"
                      id="ltr"
                      type="radio"
                      class="custom-radio-field"
                    />
                    <span class="custom-radio-span"></span>
                  </div>
                  <label for="ltr" class="db-field-label">{{ $t('label.ltr') }}</label>
                </div>
                <div class="db-field-radio">
                  <div class="custom-radio">
                    <input
                      :value="enums.displayModeEnum.RTL"
                      v-model="props.form.display_mode"
                      type="radio"
                      id="rtl"
                      class="custom-radio-field"
                    />
                    <span class="custom-radio-span"></span>
                  </div>
                  <label for="rtl" class="db-field-label">{{ $t('label.rtl') }}</label>
                </div>
              </div>
            </div>

            <div class="form-col-12 sm:form-col-6">
              <label class="db-field-title required" for="active">{{ $t('label.status') }}</label>
              <div class="db-field-radio-group">
                <div class="db-field-radio">
                  <div class="custom-radio">
                    <input
                      :value="enums.statusEnum.ACTIVE"
                      v-model="props.form.status"
                      id="active"
                      type="radio"
                      class="custom-radio-field"
                    />
                    <span class="custom-radio-span"></span>
                  </div>
                  <label for="active" class="db-field-label">{{ $t('label.active') }}</label>
                </div>
                <div class="db-field-radio">
                  <div class="custom-radio">
                    <input
                      :value="enums.statusEnum.INACTIVE"
                      v-model="props.form.status"
                      type="radio"
                      id="inactive"
                      class="custom-radio-field"
                    />
                    <span class="custom-radio-span"></span>
                  </div>
                  <label for="inactive" class="db-field-label">{{ $t('label.inactive') }}</label>
                </div>
              </div>
            </div>

            <div class="form-col-12">
              <div class="modal-btns">
                <button type="button" class="modal-btn-outline modal-close" @click="reset">
                  <i class="lab lab-close"></i>
                  <span>{{ $t('button.close') }}</span>
                </button>

                <button type="submit" class="db-btn py-2 text-white bg-primary">
                  <i class="lab lab-save"></i>
                  <span>{{ $t('button.save') }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import SmModalCreateComponent from '../../components/buttons/SmModalCreateComponent.vue'
import LoadingComponent from '../../components/LoadingComponent.vue'
import displayModeEnum from '../../../../enums/modules/displayModeEnum'
import statusEnum from '../../../../enums/modules/statusEnum'
import alertService from '../../../../services/alertService'
import appService from '../../../../services/appService'

export default {
  name: 'LanguageCreateComponent',
  components: { SmModalCreateComponent, LoadingComponent },
  props: ['props'],
  data() {
    return {
      loading: {
        isActive: false
      },
      enums: {
        statusEnum: statusEnum,
        displayModeEnum: displayModeEnum,
        statusEnumArray: {
          [statusEnum.ACTIVE]: this.$t('label.active'),
          [statusEnum.INACTIVE]: this.$t('label.inactive')
        }
      },
      image: '',
      errors: {}
    }
  },
  computed: {
    addButton: function () {
      return { title: this.$t('button.add_language') }
    }
  },
  methods: {
    changeImage: function (e) {
      this.image = e.target.files[0]
    },
    reset: function () {
      appService.modalHide()
      this.$store.dispatch('language/reset').then().catch()
      this.errors = {}
      this.$props.props.form = {
        name: '',
        code: '',
        display_mode: displayModeEnum.LTR,
        status: statusEnum.ACTIVE
      }
      if (this.image) {
        this.image = ''
        this.$refs.imageProperty.value = null
      }
    },

    save: function () {
      try {
        const fd = new FormData()
        fd.append('name', this.props.form.name)
        fd.append('code', this.props.form.code)
        fd.append('display_mode', this.props.form.display_mode)
        fd.append('status', this.props.form.status)
        if (this.image) {
          fd.append('image', this.image)
        }

        const tempId = this.$store.getters['language/temp'].temp_id
        this.loading.isActive = true
        this.$store
          .dispatch('language/save', {
            form: fd,
            search: this.props.search
          })
          .then((res) => {
            appService.modalHide()
            this.loading.isActive = false
            alertService.successFlip(tempId === null ? 0 : 1, this.$t('menu.languages'))
            this.props.form = {
              title: '',
              description: '',
              display_mode: displayModeEnum.LTR,
              status: statusEnum.ACTIVE
            }
            this.image = ''
            this.errors = {}
            this.$refs.imageProperty.value = null
          })
          .catch((err) => {
            this.loading.isActive = false
            this.errors = err.response.data.errors
          })
      } catch (err) {
        this.loading.isActive = false
        alertService.error(err)
      }
    }
  }
}
</script>
