<template>
  <button type="button" class="absolute -top-2 -right-2 text-red-500">
    <i class="lab lab-close"></i>
  </button>
</template>

<script>
export default {
  name: 'SmTimeSloteDeleteComponent.vue'
}
</script>

<style scoped></style>
