import axiosInstance from '../../plugin/axios'

export const socialMedia = {
  namespaced: true,
  state: {
    lists: []
  },

  getters: {
    lists: function (state) {
      return state.lists
    }
  },

  actions: {
    lists: function (context) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get('admin/setting/social-media')
          .then((res) => {
            context.commit('lists', res.data.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    save: function (context, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/admin/setting/social-media`, payload)
          .then((res) => {
            context.commit('lists', payload)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },

  mutations: {
    lists: function (state, payload) {
      state.lists = payload
    }
  }
}
