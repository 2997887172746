<template>
  <LoadingComponent :props="loading" />

  <div class="db-card db-tab-div active">
    <div class="db-card-header border-none">
      <h3 class="db-card-title">{{ $t('menu.languages') }}</h3>
      <div class="db-card-filter">
        <TableLimitComponent :method="list" :search="props.search" :page="paginationPage" />
        <LanguageCreateComponent :props="props" />
      </div>
    </div>

    <div class="db-table-responsive">
      <table class="db-table stripe">
        <thead class="db-table-head">
          <tr class="db-table-head-tr">
            <th class="db-table-head-th">
              {{ $t('label.name') }}
            </th>
            <th class="db-table-head-th">
              {{ $t('label.code') }}
            </th>
            <th class="db-table-head-th">
              {{ $t('label.status') }}
            </th>
            <th class="db-table-head-th">
              {{ $t('label.action') }}
            </th>
          </tr>
        </thead>
        <tbody class="db-table-body" v-if="languages.length > 0">
          <tr class="db-table-body-tr" v-for="language in languages" :key="language">
            <td class="db-table-body-td" v-if="site_default_language === language.id">
              {{ textShortener(language.name) }}({{ $t('label.default') }})
            </td>
            <td class="db-table-body-td" v-else>
              {{ textShortener(language.name) }}
            </td>
            <td class="db-table-body-td">
              {{ textShortener(language.code) }}
            </td>
            <td class="db-table-body-td">
              <span :class="statusClass(language.status)">
                {{ enums.statusEnumArray[language.status] }}
              </span>
            </td>
            <td class="db-table-body-td">
              <div class="flex justify-start items-center sm:items-start sm:justify-start gap-1.5">
                <SmViewComponent :link="'admin.settings.language.show'" :id="language.id" />
                <SmModalEditComponent @click="edit(language)" />
                <SmDeleteComponent
                  @click="destroy(language.id)"
                  v-if="site_default_language != language.id && language.id !== 1"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-6">
      <PaginationSMBox :pagination="pagination" :method="list" />
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <PaginationTextComponent :props="{ page: paginationPage }" />
        <PaginationBox :pagination="pagination" :method="list" />
      </div>
    </div>
  </div>
</template>
<script>
import LoadingComponent from '../../components/LoadingComponent.vue'
import LanguageCreateComponent from './LanguageCreateComponent.vue'
import alertService from '../../../../services/alertService'
import PaginationTextComponent from '../../components/pagination/PaginationTextComponent.vue'
import PaginationBox from '../../components/pagination/PaginationBox.vue'
import PaginationSMBox from '../../components/pagination/PaginationSMBox.vue'
import appService from '../../../../services/appService'
import statusEnum from '../../../../enums/modules/statusEnum'
import TableLimitComponent from '../../components/TableLimitComponent.vue'
import SmDeleteComponent from '../../components/buttons/SmDeleteComponent.vue'
import SmModalEditComponent from '../../components/buttons/SmModalEditComponent.vue'
import SmViewComponent from '../../components/buttons/SmViewComponent.vue'
import displayModeEnum from '../../../../enums/modules/displayModeEnum'

export default {
  name: 'LanguageListComponent',
  components: {
    TableLimitComponent,
    PaginationSMBox,
    PaginationBox,
    PaginationTextComponent,
    LanguageCreateComponent,
    LoadingComponent,
    SmDeleteComponent,
    SmModalEditComponent,
    SmViewComponent
  },
  data() {
    return {
      loading: {
        isActive: false
      },
      enums: {
        statusEnum: statusEnum,
        statusEnumArray: {
          [statusEnum.ACTIVE]: this.$t('label.active'),
          [statusEnum.INACTIVE]: this.$t('label.inactive')
        }
      },
      props: {
        form: {
          name: '',
          code: '',
          display_mode: displayModeEnum.LTR,
          status: statusEnum.ACTIVE
        },
        search: {
          paginate: 1,
          page: 1,
          per_page: 10,
          order_column: 'id',
          order_type: 'desc'
        }
      },
      site_default_language: null
    }
  },
  mounted() {
    this.list()
    this.siteList()
  },
  computed: {
    languages: function () {
      return this.$store.getters['language/lists']
    },
    pagination: function () {
      return this.$store.getters['language/pagination']
    },
    paginationPage: function () {
      return this.$store.getters['language/page']
    }
  },
  methods: {
    statusClass: function (status) {
      return appService.statusClass(status)
    },
    textShortener: function (text, number = 30) {
      return appService.textShortener(text, number)
    },
    siteList: function () {
      this.loading.isActive = true
      this.$store
        .dispatch('site/lists')
        .then((res) => {
          this.site_default_language = res.data.data.site_default_language
          this.loading.isActive = false
        })
        .catch((err) => {
          this.loading.isActive = false
        })
    },
    list: function (page = 1) {
      this.loading.isActive = true
      this.props.search.page = page
      this.$store
        .dispatch('language/lists', this.props.search)
        .then((res) => {
          this.loading.isActive = false
        })
        .catch((err) => {
          this.loading.isActive = false
        })
    },
    edit: function (language) {
      appService.modalShow()
      this.loading.isActive = true
      this.$store.dispatch('language/edit', language.id)
      this.props.form = {
        name: language.name,
        code: language.code,
        display_mode: language.display_mode,
        status: language.status
      }
      this.loading.isActive = false
    },
    destroy: function (id) {
      appService
        .destroyConfirmation()
        .then((res) => {
          try {
            this.loading.isActive = true
            this.$store
              .dispatch('language/destroy', {
                id: id,
                search: this.props.search
              })
              .then((res) => {
                this.loading.isActive = false
                alertService.successFlip(null, this.$t('menu.languages'))
              })
              .catch((err) => {
                this.loading.isActive = false
                alertService.error(err.response.data.message)
              })
          } catch (err) {
            this.loading.isActive = false
            alertService.error(err.response.data.message)
          }
        })
        .catch((err) => {
          this.loading.isActive = false
        })
    }
  }
}
</script>
