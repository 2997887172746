<template>
  <button type="button" data-modal="#sidebar" class="db-btn-outline info text-xs">
    <i class="lab lab-edit-line"></i><span>{{ $t('button.edit') }}</span>
  </button>
</template>

<script>
export default {
  name: 'SmSidebarModalEditComponent.vue'
}
</script>
